import './Headline.css';

interface HeadlineProps {
  headline: string;
  url: string;
  isMain?: boolean;
  urlToImage: string;
}

function Headline({ headline, url, isMain = false, urlToImage }: HeadlineProps) {
  return (
    <div className={isMain ? 'main-headline headline' : 'headline'}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="headline-image" src={urlToImage} alt={headline} />
        <div className="headline-text">{headline}</div>
      </a>
    </div>
  );
}

export default Headline;
