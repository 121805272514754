import { useState, useEffect } from 'react';
import Header from './components/Header';
import Column from './components/Column';
import Headline from './components/Headline';
import headlines from './data/headlines.json';
import './App.css';

function App() {
  // Theme state: 'light' or 'dark'
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | null;
    if (savedTheme) {
      return savedTheme;
    }
    // Default to system preference
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });

  // Apply theme by setting a data attribute on the body
  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  // Theme toggle handler
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // headlines sorted highest importance first and then divided evenly into 3 columns (for however many there are)
  const columns = headlines.headlines;

  return (
    <div className="App">
      <Header />

      <button className="theme-toggle-button" onClick={toggleTheme} aria-label="Toggle Theme">
        {theme === 'light' ? '🌙' : '☀️'}
      </button>

      <div className="main-headline-container">
        <Headline
          headline={headlines.main.headline}
          url={headlines.main.url}
          isMain={true}
          urlToImage={headlines.main.urlToImage}
        />
      </div>

      <div className="content">
        {columns.map((col, index: number) => (
          <Column key={index}>
            {col.map(({ headline, url, urlToImage }, idx: number) => (
              <Headline key={idx} headline={headline} url={url} urlToImage={urlToImage || ''} />
            ))}
          </Column>
        ))}
      </div>
    </div>
  );
}

export default App;
